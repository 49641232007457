/**
 * The section accordion is used when the content spans the whole width of the container, like page sections.
 *
 * @module views/components/AccordionGroup
 * @memberof -Common
 */
import './AccordionGroup.scss';

import React from 'react';

import PropTypes from 'prop-types';

import AccordionSection from '../AccordionSection/AccordionSection';

/**
 * Represents a AccordionGroup component
 *
 * @method
 * @param {AccordionGroupProps} props - React properties passed from composition
 * @returns AccordionGroup
 */
export const AccordionGroup = function( props ){
  const { accordionList, detailAccordion, expandAccessibilityLabel, collapseAccessibilityLabel } = props ;
  return (
    <div data-testid='accordion_group'
      className='AccordionGroup'
    >
      { accordionList?.length > 0 && accordionList.map( ( accordionLists, index ) => (
        <AccordionSection
          data-testid='accordion_section'
          title={ accordionLists.title }
          body={ accordionLists.body }
          bodyStyle={ accordionLists.bodyStyle }
          key={ index }
          detailAccordion={ detailAccordion }
          expandAccessibilityLabel={ expandAccessibilityLabel }
          collapseAccessibilityLabel={ collapseAccessibilityLabel }
          expandAction={ accordionLists.expandAction }
          collapseAction={ accordionLists.collapseAction }
        />
      ) )
      }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef ComponentNameProps
 * @type {object}
 * @property {array} accordionList - Array of accordion items (title, body, bodyStyle)
 * @property {string} expandAccessibilityLabel - Sets the expand Accesibility Label
 * @property {string} collapseAccessibilityLabel - Sets the collapsed Accesibility Label
 * @property {bool} detailAccordion - Sets whether the accordion is a detail or section accordion
 */
export const propTypes =  {
  /** Sets the brand title text. */
  accordionList: PropTypes.arrayOf(
    PropTypes.shape( {
      title: PropTypes.string,
      body: PropTypes.string,
      bodyStyle: PropTypes.string
    } )
  ),
  expandAccessibilityLabel: PropTypes.string,
  collapseAccessibilityLabel: PropTypes.string,
  detailAccordion: PropTypes.bool
};

AccordionGroup.propTypes = propTypes;

export default AccordionGroup;
